import React from "react"
import Layout from "../../components/layout/Layout"
import Seo from "../../components/layout/Seo"
import OurTeam from "../../components/OurTeam"

export default function TeamPage() {
  return (
    <Layout path='/about-us'>
      <Seo
        title="About our team"
        description="About chamberlain dental clinic team in clearwater Florida."
      />
      <OurTeam teamPage />
    </Layout>
  )
}
